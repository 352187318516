import { css, injectGlobal } from '@emotion/css';
import React, { ReactElement, ReactNode } from 'react';
import globalStyles from '../styles/global';
import prismStyles from '../styles/prism';
import Footer from './Footer';
import Navbar from './Navbar';
import SEO from './SEO';

// eslint-disable-next-line no-unused-expressions
injectGlobal`
  ${prismStyles}
  ${globalStyles};
`;

const gridStyle = css`
  display: grid;
  grid-template-areas:
    'header header header'
    '. main .'
    'footer footer footer';
  grid-template-columns: minmax(1em, 1fr) minmax(0, 48rem) minmax(1em, 1fr);
  grid-template-rows: 4rem auto auto;
`;

const mainStyle = css`
  grid-area: main;
  margin: 4rem auto 2rem;
  max-width: 48rem;
  padding: 4rem 1rem;
  width: 100%;

  @supports (display: grid) {
    margin: 0 auto;
  }
`;

interface Props {
  children: ReactNode;
}

const Layout = ({ children }: Props): ReactElement => (
  <div className={gridStyle}>
    <SEO />
    <Navbar />

    <section className={mainStyle}>{children}</section>

    <Footer />
  </div>
);

export default Layout;
