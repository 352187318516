import { css } from '@emotion/css';
import React, { ReactElement } from 'react';
import { FaEnvelope, FaGithub, FaLinkedin, FaTwitter } from 'react-icons/all';
import { breakpoints, colours } from '../styles/variables';

const footerStyle = css`
  background: ${colours.tertiary};
  color: #fff;
  font-family: 'Roboto Slab', 'Georgia', serif;
  grid-area: footer;

  a {
    text-decoration: none;
  }

  a:hover {
    color: ${colours.secondary};
  }
`;

const mainStyle = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 60rem;
  padding: 1rem;
  text-align: center;

  @media (max-width: ${breakpoints.desktop}) {
    align-items: center;
    flex-direction: column;
  }
`;

const copyrightStyle = css`
  margin: 0.3rem 0;
`;

const socialIconsStyle = css`
  display: flex;
  list-style: none;
  margin: 0.3rem 0;
`;

const socialIconStyle = css`
  margin-right: 0.8rem;

  a {
    align-items: center;
    display: flex;
  }

  span {
    margin-left: 0.2rem;
  }
`;

const Footer = (): ReactElement => (
  <footer className={footerStyle}>
    <div className={mainStyle}>
      <span className={copyrightStyle}>Copyright &copy; Nicholas Tsim</span>

      <ul className={socialIconsStyle}>
        <li className={socialIconStyle}>
          <a href="mailto:nicholastsim@gmail.com">
            <FaEnvelope />
            <span>Email</span>
          </a>
        </li>
        <li className={socialIconStyle}>
          <a href="https://twitter.com/nick_tsim">
            <FaTwitter />
            <span>Twitter</span>
          </a>
        </li>
        <li className={socialIconStyle}>
          <a
            href="https://github.com/ntsim"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaGithub />
            <span>GitHub</span>
          </a>
        </li>
        <li className={socialIconStyle}>
          <a
            href="https://www.linkedin.com/in/nicholas-tsim-776b408a/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedin />
            <span>LinkedIn</span>
          </a>
        </li>
      </ul>
    </div>
  </footer>
);

export default Footer;
