export const colours = {
  primary: '#3498db',
  secondary: '#ff9931',
  tertiary: '#002a4a',
  grey: '#646464',
  white: '#fff',
};

export const breakpoints = {
  mobile: '540px',
  desktop: '960px',
};
