import { css, cx } from '@emotion/css';
import Link from 'gatsby-link';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { FaGithub } from 'react-icons/all';
import { colours } from '../styles/variables';

const navBarHeight = 16 * 3.5;

const navStyle = css`
  background: ${colours.tertiary};
  font-family: 'Roboto Slab', 'Georgia', serif;
  grid-area: header;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: transform 500ms ease-in-out;
  z-index: 100;

  a {
    color: #fff;
    text-decoration: none;
  }

  a:hover {
    background: none;
    color: ${colours.secondary};
  }

  a:focus {
    outline: 2px solid ${colours.secondary};
    outline-offset: 5px;
  }
`;

const navHiddenStyle = css`
  transform: translateY(-100%);
`;

const activeStyle = css`
  border-bottom: 1px solid ${colours.secondary};
  color: ${colours.secondary};
`;
const mainStyle = css`
  align-items: center;
  display: flex;
  height: ${navBarHeight}px;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 60rem;
  padding: 0 1rem;
`;

const brandStyle = css`
  font-size: 1.2rem;
  margin: 0;
`;

const itemListStyle = css`
  align-items: center;
  display: flex;
  list-style: none;
  margin: 0;
`;

const itemStyle = css`
  font-size: 1.2rem;
  margin-bottom: 0;
  margin-left: 1.25rem;

  a {
    align-items: center;
    display: flex;
  }
`;

const Navbar = (): ReactElement => {
  const prevScrollY = useRef(0);
  const [isHidden, setHidden] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrollingDown = window.scrollY > prevScrollY.current;
      prevScrollY.current = window.scrollY;

      if (window.scrollY > navBarHeight && isScrollingDown && !isHidden) {
        setHidden(true);
        return;
      }

      if (!isScrollingDown && isHidden) {
        setHidden(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isHidden]);

  return (
    <nav className={cx(navStyle, isHidden && navHiddenStyle)}>
      <div className={mainStyle}>
        <Link to="/" className={brandStyle}>
          Nicholas Tsim
        </Link>

        <ul className={itemListStyle}>
          <li className={itemStyle}>
            <Link to="/" activeClassName={activeStyle}>
              Blog
            </Link>
          </li>
          <li className={itemStyle}>
            <Link to="/about" activeClassName={activeStyle}>
              About
            </Link>
          </li>
          <li className={itemStyle}>
            <a
              href="https://github.com/ntsim"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaGithub />
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
