import { css } from '@emotion/css';
import { darken, hsla, lighten } from 'polished';
import { colours } from './variables';

const globalStyles = css`
  *:focus {
    outline: 3px solid ${colours.primary};
    outline-offset: 3px;

    @media (prefers-reduced-motion: no-preference) {
      transition: outline-offset 0.1s ease;
    }
  }

  a {
    color: inherit;

    &:hover {
      color: ${colours.primary};
      transition: color 0.2s ease;
    }
  }

  blockquote {
    background: ${hsla(0, 0, 0, 0.05)};
    border-left: 3px solid ${colours.primary};
    color: ${colours.grey};
    margin-left: 0;
    margin-right: 0;
    padding: 1.2rem;
  }

  .gatsby-resp-image-figcaption {
    font-style: italic;
    margin-top: 0.5rem;
    text-align: center;
  }

  code.language-text {
    background: ${lighten(0.3, colours.secondary)};
    border-radius: 4px;
    color: ${darken(0.22, colours.secondary)};
    font-family: 'IBM Plex Mono', Consolas, 'DejaVu Sans Mono', Menlo, Monaco,
      monospace;
    font-size: 0.85em;
    padding: 0.15rem 0.25rem;
  }

  .gatsby-highlight {
    border-radius: 6px;
    box-shadow: 2px 2px 8px ${lighten(0.4, colours.grey)};
    margin-bottom: 1.8rem;
    overflow: auto;

    pre {
      margin: 0 !important;
    }

    code {
      background: none;
      color: inherit;
      line-height: 1.4;
      padding: 0;
    }

    code.language-text {
      font-size: 0.85rem;
    }
  }
`;

export default globalStyles;
