import { useLocation } from '@reach/router';
import { graphql, useStaticQuery } from 'gatsby';
import React, { ReactElement } from 'react';
import { Helmet } from 'react-helmet';
import { SiteMetadata } from '../types/site';

interface Query {
  site: {
    siteMetadata: SiteMetadata;
  };
}

const SEO = (): ReactElement => {
  const { pathname } = useLocation();
  const {
    site: { siteMetadata },
  } = useStaticQuery<Query>(query);

  const description =
    'Personal website and blog of Nicholas Tsim. Join me for posts on all things programming and tech!';

  const url = `${siteMetadata.siteUrl}${pathname}`;

  return (
    <Helmet
      defaultTitle={siteMetadata.title}
      titleTemplate={`%s - ${siteMetadata.title}`}
    >
      <meta name="description" content={description} />

      <meta name="og:url" content={url} />
      <meta name="og:title" content={siteMetadata.title} />
      <meta name="og:site_name" content={siteMetadata.title} />
      <meta name="og:description" content={description} />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content={siteMetadata.twitterName} />
      <meta name="twitter:title" content={siteMetadata.title} />
      <meta name="twitter:description" content={description} />
    </Helmet>
  );
};

export default SEO;

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        siteUrl
        title
        twitterName
      }
    }
  }
`;
